@font-face {
  font-family: "Roboto-Black";
  src: url("assets/fonts/Roboto/Roboto-Black.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("assets/fonts/Roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Medium-Italic";
  src: url("assets/fonts/Roboto/Roboto-Medium-Italic.ttf");
}

@font-face {
  font-family: "RobotoCondensed-Bold";
  src: url("assets/fonts/Roboto/RobotoCondensed-Bold.ttf");
}

@font-face {
  font-family: "RobotoCondensed-Medium";
  src: url("assets/fonts/Roboto/RobotoCondensed-Medium.ttf");
}
@font-face {
  font-family: "RobotoCondensed-Regular";
  src: url("assets/fonts/Roboto/RobotoCondensed-Regular.ttf");
}
@font-face {
  font-family: "Roboto-Italic";
  src: url("assets/fonts/Roboto/Roboto-Italic.ttf");
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("assets/fonts/Roboto/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("assets/fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Raleway-Variable";
  src: url("assets/fonts/Roboto/Railway/Raleway-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Raleway-Variable-Italic";
  src: url("assets/fonts/Roboto/Railway/Raleway-Italic-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Raleway-Bold";
  src: url("assets/fonts/Roboto/Railway/Raleway-Bold.ttf");
}
@font-face {
  font-family: "Raleway-ExtraBold";
  src: url("assets/fonts/Roboto/Railway/Raleway-ExtraBold.ttf");
}

@font-face {
  font-family: "Raleway-Medium";
  src: url("assets/fonts/Roboto/Railway/Raleway-Medium.ttf");
}
@font-face {
  font-family: "Raleway-Regular";
  src: url("assets/fonts/Roboto/Railway/Raleway-Regular.ttf");
}
@font-face {
  font-family: "Raleway-SemiBold";
  src: url("assets/fonts/Roboto/Railway/Raleway-SemiBold.ttf");
}

/* 
====================================================================
  Loading Transition
====================================================================

 */
.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #000000bd;
}
.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
  text-align: center;
}
.ctn-preloader .animation-preloader .icon {
  display: inline-block;
  position: relative;
}
.ctn-preloader .animation-preloader .txt-loading {
  user-select: none;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: #f9c9b3;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 30px;
  position: absolute;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: "Roboto-Regular";
  letter-spacing: 12px;
  display: inline-block;
  color: #ee6931;
  position: relative;
  font-size: 50px;
  line-height: 30px;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}
.ctn-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px !important;
}

.Roboto-Black {
  font-family: "Roboto-Black" !important;
}
.Roboto-Bold {
  font-family: "Roboto-Bold" !important;
}
.RobotoCondensed-Bold {
  font-family: "RobotoCondensed-Bold" !important;
}
.RobotoCondensed-Medium {
  font-family: "RobotoCondensed-Medium" !important;
}
.RobotoCondensed-Regular {
  font-family: "RobotoCondensed-Regular" !important;
}
.Roboto-Italic {
  font-family: "Roboto-Italic" !important;
}
.Roboto-Medium {
  font-family: "Roboto-Medium" !important;
}
.Roboto-Regular {
  font-family: "Roboto-Regular" !important;
}
.Roboto-Medium-Italic {
  font-family: "Roboto-Medium-Italic" !important;
}

.side-bar-scroll:-webkit-scrollbar {
  width: 7px;
}

.side-bar-scroll::-webkit-scrollbar-track {
  background: #fce6dd;
}

.side-bar-scroll::-webkit-scrollbar-thumb {
  background: #f9c9b3;
  border-radius: 5px !important;
  cursor: pointer;
  box-shadow: 0px 2px 4px -1px #191847ae, 0px 4px 5px 0px #00000024,
    0px 1px 10px 0px #191847b4;
}

.body::-webkit-scrollbar {
  width: 7px;
}
body::-webkit-scrollbar-track {
  background: white;
}
body::-webkit-scrollbar-thumb {
  background: #ee6931;
  border-radius: 0;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 100;
}
*::-webkit-scrollbar-track {
  background: white;
}
*::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 100;
}
.padding-0 {
  padding: 0px;
}
.padding-5 {
  padding: 5px;
}
.padding-10 {
  padding: 10px;
}
.padding-20 {
  padding: 20px;
}
.selected-row {
  /* border: 1px solid red; */
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}
.unselected-row {
  /* border: 1px solid red; */
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
td {
  border-bottom: none !important;
}
.text_feild_small {
  height: 36px !important;
}
.disabled-bg {
  background-color: #efefef !important;
}
/* .MuiOutlinedInput-root {
  height: 43px !important;
} */
.ps__rail-y {
  display: none !important;
}
.border-bottom-black {
  border-bottom: 1px solid #3e3e3e;
}

.Toastify__toast-body {
  font-size: 14px !important;
}

.MuiInputAdornment-root {
  margin-left: 0px !important;
}
.small-font {
  font-size: 14px !important;
}

.react-mic {
  height: 34px;
  width: calc(100% - 20px);
  background: transparent;
  display: flex;
}
.audio-recorder-options {
  display: none;
}
.audio-recorder {
  background-color: white !important;
  box-shadow: none !important;
}
.banner-img {
  transition: 0.5s; /* Animation */
  border-radius: 16px;
}
.banner-img:hover {
  transform: scale(1.5);
  /* transition: 0.5s; Animation */
  filter: drop-shadow(1px 2px 2px lightgray);
}

.capitalize-css {
  text-transform: capitalize;
}

.btn-link {
  cursor: pointer;
  border: none;
  color: blue;
  /* height: 21px; */
  font-size: 13px;
  text-align: center;
  /* width: 70px; */
  font-family: "Roboto-Medium";
  text-transform: capitalize;
}
.btn-link:hover {
  cursor: pointer;

  color: rgb(7, 7, 117);
  background-color: white;
  text-decoration: underline;
  text-transform: capitalize;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent; /* Slightly tinted transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(7px); /* Apply blur effect */
}

.preview a {
  color: white;
  background: #ee6931;
  text-decoration: none;
  /* font-weight: bold; */
  /* border: 2px solid red; */
  border-radius: 9px;
  padding: 3px;
  font-family: "Roboto-Regular";
}

.preview a:hover {
  background: "#ee6931";
  color: "white";
  transition: "1s";
  box-shadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)";
}
.custom-quill-instance .ql-editor a {
  color: white !important;
  background: #ee6931;
  text-decoration: none;
  /* font-weight: bold; */
  /* border: 2px solid red; */
  border-radius: 35px;
  padding: 15px;
  font-family: "Roboto-Regular";
  cursor: pointer !important;
}

.custom-quill-instance .ql-snow a {
  /* color:#3e3e3e !important; */
  cursor: pointer !important;
}

.custom-quill-instance .ql-snow .ql-editor a {
  text-decoration: none !important;
}

.custom-quill-instance .ql-editor {
  height: 80vh;
  overflow-y: scroll;
}
.custom-quill-instance .ql-custom {
  background-color: "red" !important;
}

.custom-quill-instance .ql-container.ql-snow {
  height: auto;
}
.custom-quill-instance  .ql-editor {
  padding: 0px !important;
}
.custom-quill-instance .ql-editor p,
.custom-quill-instance .ql-editor h1,
.custom-quill-instance .ql-editor h2,
.custom-quill-instance .ql-editor h3,
.custom-quill-instance .ql-editor h4,
.custom-quill-instance .ql-editor h5,
.custom-quill-instance .ql-editor h6,
.custom-quill-instance .ql-editor ul {
  padding: 0px 40px !important;
}
/* Remove padding from <p> tags that contain <img> */
.custom-quill-instance  .ql-editor p:has(img) {
  padding: 0 !important;
}
.custom-quill-instance .ql-editor ul li::before {
  color: #EE6931 !important;
  list-style: "none" !important;
  content: "\25CF" !important;
}

.custom-quill-instance .ql-editor ul li:not(:last-child) {
  padding-bottom: 10px; /* Apply padding to all except the last */
}

.custom-quill-instance .ql-editor p:first-of-type img {

  border-radius: 12px 12px 0px 0px !important;
}
/* category selection css */

.custom_submenu .szh-menu__item::after {
  color: #ee6931;
}
.szh-menu__item--type-checkbox.szh-menu__item--checked::before {
  color: #ee6931;
}
.szh-menu-container{
    font-family: Raleway-SemiBold;

}
.scroll-div-assign-categroy:-webkit-scrollbar {
  width: 7px;
}

.scroll-div-assign-categroy::-webkit-scrollbar-track {
  background: white;
}

.scroll-div-assign-categroy::-webkit-scrollbar-thumb {
  background: #f9c9b3;
  border-radius: 5px !important;
  cursor: pointer;
 
}


.custom-quill-particular .ql-container.ql-snow {
  border-radius: 10px !important;

}
.custom-quill-particular .ql-editor {
  font-family: "Roboto-Medium" ;
  font-size: 16px;
  color: #333333;

}